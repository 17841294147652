<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-form')"
    route="form_view"
    :table-attributes="tableAttributes"
    auto-complete-id="form"
    :data-list-config="dataListConfig"
    :default-sort="defaultSort"
    :search-placeholder="t('hit-base.common.search-linked-entity')"
  >
    <template #item.status.display="{formData}">
      <span>
        {{ t(`${new Form({status: formData.status}).getStatusTextKey()}`) }}
      </span>
    </template>
  </hit-entity-browser>
</template>

<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {
  HitContainerAttribute,
  ATTRIBUTE_TYPES,
} from '../../container/HitContainerAttribute';
import {useI18n} from 'vue-i18n';
import {Form} from '@hit/base';

export default {
  name: 'HitFormBrowser',
  components: {
    HitEntityBrowser,
  },
  inject: ['tHitField', 'toSnakeCase'],
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      tableAttributes: {
        form: new HitContainerAttribute(ATTRIBUTE_TYPES.ML_WITH_FALLBACK, {
          column: 'form_definition_designation_LOCALE',
          tableProperties: {
            header: this.t('hit-base.common.form'),
            maxWidth: '1fr',
          },
        }),
        linkedEntity: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'linked_entity',
          tableProperties: {
            header: this.t('hit-base.form.linked-entity'),
            mainSearch: true,
          },
        }),
        formDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
          column: 'form_date',
          tableProperties: {
            header: this.t('hit-app.form.form-date'),
          },
        }),
        creationDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATETIME, {
          column: 'creation_date',
          tableProperties: {
            header: this.t('hit-app.form.creation-date'),
            filterable: true,
            maxWidth: '1fr',
          },
        }),
        modificationDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATETIME, {
          column: 'modification_date',
          tableProperties: {
            header: this.t('hit-app.form.modification-date'),
            filterable: true,
            maxWidth: '1fr',
          },
        }),
      },
      dataListConfig: {
        zone1Field: 'author',
        zone2Field: 'form',
        zone3Field: 'formDate',
      },
      defaultSort: {property: 'modificationDate', order: 'desc'},
    };
  },
  computed: {
    Form() {
      return Form;
    },
  },
};
</script>
