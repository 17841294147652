import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_hit_button = _resolveComponent("hit-button");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.$attrs.class)
    }, [
        _createVNode(_component_hit_button, {
            label: _ctx.label,
            to: _ctx.to,
            disabled: _ctx.disabled,
            tooltip: _ctx.t('hit-base.common.add-item'),
            color: "accent",
            "prefix-icon": "add",
            onClick: _ctx.handleClick
        }, null, 8, ["label", "to", "disabled", "tooltip", "onClick"])
    ], 2));
}
