<template>
  <hit-select
    :value="container.data[attribute]"
    :options="options"
    :disabled="container.attributes[attribute].readOnly"
    @change="(value) => container.handleChange(attribute, value)"
  >
    <template
      v-for="({}, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </hit-select>
</template>
<script>
import {HitInputRichText, HitSelect} from '@hit/components';
export default {
  name: 'HitContainerInputSelect',
  components: {
    HitSelect,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
};
</script>
