<template>
  <div
    class="flex flex-row gap-1 flex-wrap flex-1"
    :class="{
      'flex-1': fullWidth,
    }"
  >
    <div
      v-for="item in entitiesToDisplay"
      :key="item.designation"
      class="font-medium min-h-5 items-center overflow-x-hidden"
      :class="{
        'cursor-pointer': clickable,
        'w-full': fullWidth,
      }"
      @click.stop="handleClick(item.id)"
    >
      <div
        class="flex-row flex py-1 px-1 rounded"
        :style="generateCssStyle(item)"
      >
        <div
          class="px-1 text-xs font-normal leading-none max-w-full leading-snug flex-1"
        >
          <div>
            <span>{{ item.designation }}</span>
          </div>
          <div>
            <slot
              name="additionalLine"
              :item="item"
            />
          </div>
        </div>
        <div class="flex flex-col items-start">
          <hit-icon
            v-if="clearable"
            icon="clear"
            clickable
            @click="$emit('remove', item.id)"
          />
          <hit-icon
            v-for="(icon, index) in customIcons"
            :key="index"
            :icon="icon.icon"
            :size="customIconSize"
            clickable
            @click="$emit(icon.event, item)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {HitIcon} from '../icon';
import {ColourUtils} from '../../utils/colour';

export default {
  name: 'HitEntityList',
  components: {HitIcon},
  props: {
    /**
     * Array with the different entities to display. Each entity has an object with:
     *    - id: id used to identify the record
     *    - designation: designation that will be displayed
     */
    entitiesToDisplay: {
      type: Array,
      required: true,
    },

    /**
     * Boolean to activate the display of the cancel icon behind each record
     */
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * Array of custom icons that are displayed at the end of the entity list
     */
    customIcons: {
      type: Array,
      required: false,
      default: null,
    },

    /**
     * Use a specific icon size for the custom icon
     */
    customIconSize: {
      type: String,
      required: false,
      default: 'xs',
    },

    /**
     * Boolean to disable the click event on the designation label
     */
    clickable: {
      type: Boolean,
      required: false,
      default: true,
    },

    /**
     * Flag to make the entity list full width of the parent element
     */
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  /**
   * We need to define all the events dynamically because they depend on the property
   */
  emits() {
    return this.emitsList;
  },

  /**
   * To be able to declare the events that can be emitted from this component,
   * we need to generate the events dynamically based on the custom icons prop
   */
  computed: {
    emitsList() {
      let emits = this.customIcons.map((icon) => icon.event);
      emits = emits.concat(['remove']);
      return emits;
    },
  },

  methods: {
    /**
     * Handles the click on the designation label
     */
    handleClick(id) {
      if (this.clickable) {
        this.$emit('click', id);
      }
    },

    /**
     * Generates the background color and the text colour for the entity designation
     */
    generateCssStyle(tag) {
      return {
        'background-color': `${
          tag.colour ? `#${tag.colour}` : 'var(--color-tag)'
        }`,
        color: `${
          tag.colour
            ? ColourUtils.getFontColorOnBg(tag.colour)
            : 'var(--text-tag)'
        }`,
      };
    },
  },
};
</script>

<style scoped>
span {
  white-space: pre-wrap;
}
</style>
