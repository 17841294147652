<template>
  <div
    v-if="!workInProgress"
    class="w-full hit-table pt-1 pl-1 pr-1 overflow-auto"
  >
    <template v-if="dynamicCard">
      <hit-table-data-list-dynamic-card
        v-for="tabLine in tabLinesToDisplay"
        :key="getItemIdentifier(tabLine.item)"
        :item="tabLine.item"
        :item-properties-to-display="itemPropertiesToDisplay"
      >
        <template
          v-for="({}, slot) of $slots"
          #[slot]="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>
      </hit-table-data-list-dynamic-card>
    </template>
    <template v-else>
      <hit-table-data-list-card
        v-for="tabLine in tabLinesToDisplay"
        :key="getItemIdentifier(tabLine.item)"
        :class="getLineClass(tabLine.item, false)"
        :item="tabLine.item"
        :data-list-config="dataListConfig"
        @click="editItem(getItemIdentifier(tabLine.item))"
      >
        <template
          v-for="({}, slot) of $slots"
          #[slot]="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>
      </hit-table-data-list-card>
    </template>
  </div>
  <div
    v-else
    class="hit-table ml-2 mt-2 mb-2"
  >
    <slot
      name="mobile-form"
      :item="formItem"
      :item-properties-to-display="itemPropertiesToDisplay"
      :disable-form-fn="cancelWIP"
      :row-validations="validations"
    >
      <hit-form
        :value="formItem"
        :autosave="autoSave"
        :cancellable="true"
        :validations="validations"
        :fixed-label-width="false"
        :before-save-fn="beforeSaveFn"
        buttons-on-same-line
        class="w-full p-1"
        @auto-save="(value) => $emit('save', value)"
        @saveAll="
          (value) => {
            if (itemBeingCreated) {
              disableCreating();
              $emit('insertLine', value);
            } else {
              disableEditing();
              $emit('saveLine', value);
            }
          }
        "
        @cancel="
          () => {
            disableEditing();
            disableCreating();
          }
        "
        @created="disableCreating"
      >
        <template
          #default="{
            formData,
            validationState,
            cancelFn,
            saveFn,
            canSave,
            manualFieldChange,
          }"
        >
          <div class="w-full grid grid-cols-2 items-center">
            <template v-for="itemProperty in itemPropertiesToDisplay">
              <!-- eslint-disable vue/require-v-for-key -->
              <div class="w-full mr-2">
                {{ itemProperty.header }}:
              </div>
              <div class="w-full mb-2 justify-items-end">
                <slot
                  :name="
                    generateCustomSlotName(itemProperty.propertyName, true)
                  "
                  :item-property="itemProperty"
                  :form-data="formData"
                  :validation-state="validationState"
                  :save-fn="saveFn"
                  :manual-field-change="manualFieldChange"
                >
                  <hit-input-generic
                    v-model:value="formData[itemProperty.propertyName]"
                    :disabled="itemProperty.readOnly"
                    :name="itemProperty.propertyName"
                    :value-type="
                      getValueType(itemProperty.propertyName, formData)
                    "
                    :validation-state="
                      validationState[itemProperty.propertyName]
                    "
                    :inline-input="true"
                    class="w-full"
                  />
                </slot>
              </div>
              <!-- eslint-enable -->
            </template>
            <div class="flex flex-row flex-start items-center">
              <hit-button
                prefix-icon="check"
                class="m-1"
                icon-size="md"
                remove-padding
                color="success"
                :disabled="!canSave"
                @click="saveFn"
              />
              <hit-button
                v-if="!autoSave"
                prefix-icon="clear"
                class="m-1"
                icon-size="md"
                remove-padding
                color="warning"
                @click="cancelFn"
              />
              <slot
                name="customIcons"
                :form-data="formData"
              />
            </div>
            <div class="w-full flex flex-row justify-end items-center">
              <hit-button
                prefix-icon="trash"
                class="m-1"
                icon-size="md"
                remove-padding
                color="danger"
                @click="deleteItem(formData)"
              />
            </div>
          </div>
        </template>
      </hit-form>
    </slot>
  </div>
</template>
<script>
import HitTableDataListCard from './HitTableDataListCard.vue';
import HitTableDataListDynamicCard from './HitTableDataListDynamicCard.vue';
import HitTableDataMixin from './HitTableDataMixin';
import {HitForm, HitInputGeneric} from '../form';
import HitButton from '../button/HitButton.vue';
export default {
  name: 'HitTableDataList',
  components: {
    HitButton,
    HitInputGeneric,
    HitForm,
    HitTableDataListCard,
    HitTableDataListDynamicCard,
  },
  mixins: [HitTableDataMixin],
  inject: {
    disableCreating: {
      default: undefined,
    },
    disableEditing: {
      default: undefined,
    },
  },
  props: {
    //can contain the fields zone1Field, zone1CustomFunction, zone2Field, ....
    dataListConfig: {
      type: Object,
      required: false,
      default: null,
    },
    dynamicCard: {
      type: Boolean,
      default: false,
    },
    tabLinesToDisplay: {
      type: Array,
      default: null,
    },
    workInProgress: Boolean,
    formItem: {
      type: Object,
      default: null,
    },
    validations: {
      type: Object,
      default: null,
    },
    itemPropertiesToDisplay: {
      type: Array,
      default: null,
    },
    autoSave: Boolean,
    itemBeingCreated: Boolean,
    beforeSaveFn: {
      type: Function,
      default() {
        return null;
      },
    },
  },
  methods: {
    deleteItem(formData) {
      this.disableCreating();
      this.disableEditing();
      this.$emit('delete-item', formData);
    },
  },
};
</script>
