<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-staff')"
    route="staff"
    auto-complete-id="staff"
    :table-attributes="tableAttributes"
    :data-list-config="dataListConfig"
    :default-sort="{property: 'no', order: 'asc'}"
    :only-load-active-records="onlyLoadActiveRecords"
    :search-placeholder="t('hit-base.common.search-name')"
    :custom-filters="customFilters"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {
  HitContainerAttribute,
  ATTRIBUTE_TYPES,
} from '../../container/HitContainerAttribute';
import {useI18n} from 'vue-i18n';
import {HitLanguageUtils} from '../../../utils';
export default {
  name: 'HitStaffBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
    forInjection: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      tableAttributes: {
        number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'no',
          tableProperties: {
            header: this.t('hit-app.common.number'),
            maxWidth: '1fr',
          },
        }),
        name: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'designation',
          tableProperties: {
            header: this.t('hit-base.common.name'),
            sharable: true,
            width: '1fr',
            mainSearch: true,
          },
        }),
        /* We still need to add first_name and last_name because they are used by other scripts */
        first_name: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING),
        last_name: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING),
        street: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-app.common.street'),
            maxWidth: '1fr',
          },
        }),
        postcode: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-app.common.postcode'),
            maxWidth: '0.5fr',
          },
        }),
        city: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-app.common.city'),
            maxWidth: '1fr',
          },
        }),
        country: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-base.common.country'),
            maxWidth: '1fr',
          },
        }),
        active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
          tableProperties: {
            propertyName: 'active',
            header: this.t('hit-app.common.active'),
            maxWidth: '0.5fr',
          },
        }),
        tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
          column: {
            table: 'tag!staff_tag',
          },
          tableProperties: {
            header: this.t('hit-base.common.tags'),
            maxWidth: '2fr',
          },
        }),
        resource: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: `cost_resource_id(id, no, ${HitLanguageUtils.localizeString(
            'designation',
            'company'
          )}, base_price, unit_id)`,
          formItemName: 'resource',
        }),
      },
      dataListConfig: {
        zone2Field: 'name',
      },
    };
  },

  computed: {
    customFilters() {
      const filters = {};
      if (this.forInjection) {
        filters['resource'] = 'not.is.null';
      }
      return filters;
    },
  },
};
</script>
